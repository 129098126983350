<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="product" :query="{}">
      <DefaultBtn
        titleButton="Salvar"
        icon="edit"
        @click="addOrUpdateProduct"
      />
    </Mural>
    <div class="content-new-training flex row col-12">
      <div class="content-thumb-training col-4">
        <div class="col-12 flex row q-pa-sm">
          <div class="col-12">
            <label class="title-form">Foto do Produto</label>
          </div>
          <div class="col-12 thumb-training q-mt-sm shadow-3">
            <q-img
              height="150px"
              :src="imageUrl"
              fit="contain"
              style="background-color: #e5e5e5"
            />
          </div>
          <div class="col-12 btn-thumb-training flex justify-between q-mt-md">
            <q-btn
              @click="sendEventAddImage"
              class="q-mt-xs"
              unelevated
              color="default-pink"
              text-color="default-white"
              label="Escolher imagem"
            />
            <input
              style="display: none"
              ref="refFileInput"
              @change="onEventFilePicked"
              type="file"
              name="upload"
              accept="image/*"
            />

            <q-btn
              @click="sendEventRemoveImage"
              class="q-mt-xs"
              outline
              color="default-pink"
              label="Remover"
            />
          </div>
        </div>
      </div>
      <div class="content-form-training col-8 page-content">
        <form>
          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form">Nome do Produto</span>
              </div>
              <div class="row">
                <q-input
                  class="col-12"
                  bg-color="grey-3"
                  v-model="product.name"
                  color="default-pink"
                  dense
                  type="text"
                  outlined
                  :rules="[
                    (val) => !!val || 'O campo nome da trilha é obrigatório.',
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form">Categoria</span>
              </div>
              <div class="row">
                <q-select
                  outlined
                  bg-color="grey-3"
                  color="default-pink"
                  class="col-7"
                  dense
                  emit-value
                  map-options
                  v-model="product.category_id"
                  :options="categories"
                  :rules="[
                    (val) => !!val || 'O campo categoria é obrigatório.',
                  ]"
                />
                <div class="col-4 q-ml-xl" style="height: 0">
                  <OutlineBtn
                    titleButton="Cadastrar categorias"
                    :showIcon="false"
                    @click="showDialogCategory = true"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form">Fornecedor</span>
              </div>
              <div class="row">
                <q-select
                  outlined
                  bg-color="grey-3"
                  color="default-pink"
                  class="col-12"
                  dense
                  emit-value
                  map-options
                  v-model="product.provider_id"
                  :options="providers"
                  :rules="[
                    (val) => !!val || 'O campo fornecedor é obrigatório.',
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm" v-if="isMaster">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form">Empresa</span>
              </div>
              <div class="row">
                <q-select
                  outlined
                  bg-color="grey-3"
                  color="default-pink"
                  class="col-12"
                  dense
                  emit-value
                  map-options
                  v-model="company_id"
                  :options="listCompanies"
                  @update:model-value="filterGroupByCompany()"
                  :rules="[(val) => !!val || 'O campo Empresa é obrigatório.']"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">
                        Não encontrado
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form">Especificar Grupos</span>
              </div>
              <div class="row">
                <q-select
                  v-model="product.groups"
                  :options="listGroups"
                  bg-color="grey-3"
                  color="default-pink"
                  class="col-12"
                  multiple
                  outlined
                  :disable="isMaster && company_id == null ? true : false"
                  dense
                  emit-value
                  map-options
                  use-input
                  input-debounce="0"
                  @filter="filterFnGroup"
                  :rules="[
                    (val) => !!val || 'O campo categoria é obrigatório.',
                  ]"
                >
                  <template v-slot:no-option>
                    <q-item>
                      <q-item-section class="text-grey">
                        Não encontrado
                      </q-item-section>
                    </q-item>
                  </template>
                  <template
                    v-slot:option="{ itemProps, opt, selected, toggleOption }"
                  >
                    <q-item v-bind="itemProps">
                      <q-item-section>
                        <q-item-label v-html="opt.label" />
                      </q-item-section>
                      <q-item-section side>
                        <q-toggle
                          :model-value="selected"
                          @update:model-value="toggleOption(opt)"
                        />
                      </q-item-section>
                    </q-item>
                  </template>
                </q-select>
              </div>
            </div>
          </div>
          <div class="col-12 flex row q-pa-sm">
            <div class="form-input col-12 column">
              <div class="row">
                <span class="title-input-form">Descrição Produto</span>
              </div>
              <div class="row">
                <q-input
                  class="col-12"
                  v-model="product.description"
                  bg-color="grey-3"
                  color="default-pink"
                  dense
                  type="textarea"
                  outlined
                />
              </div>
            </div>
          </div>
        </form>
        <AddCategotyDialog
          title="Adicionar novas categorias de produtos"
          :listCategory="categories"
          :canEdit="true"
          :canRemove="true"
          :showDialog="showDialogCategory"
          @addNewCategory="(newName, event) => addNewCategory(newName, event)"
          @editCategory="(category, event) => editCategory(category, event)"
          @disableCategory="
            (category, event) => removeCategory(category, event)
          "
          @close="() => (showDialogCategory = false)"
        />
      </div>
    </div>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
import GroupServices from "@/services/GroupServices";
import CompanyServices from "@/services/CompanyServices";
import WareHouseService from "@/services/WareHouseService";
import AddCategotyDialog from "@/components/shared/Dialog/AddCategotyDialog.vue";
import OutlineBtn from "@/components/shared/buttons/OutlineBtn.vue";

import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import InputNumber from "@/components/shared/inputs/InputNumber.vue";
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ENV } from "@/utils/env";
import { date, useQuasar } from "quasar";

export default {
  name: "NewProduct",
  components: {
    Mural,
    DefaultBtn,
    InputNumber,
    OutlineBtn,
    AddCategotyDialog,
  },
  setup() {
    let _wareHouseService = new WareHouseService();
    let _groupServices = new GroupServices();
    let _companyServices = new CompanyServices();

    let company_id = ref(null);
    let listCompanies = ref([]);
    let listGroups = ref([]);
    let optionListGroups = ref([]);
    let optionListGroupsByCompany = ref([]);
    let breadcrumbs = ref(["Adicionar Produto"]);
    const imageUrl = ref("");
    const file = ref(null);
    const refFileInput = ref(null);

    let providers = ref([]);
    let loading = ref(false);
    let isMaster = ref(false);

    let route = new useRoute();
    let router = new useRouter();
    let url_aws_bucket = ENV.URL_AWS_BUCKET;
    let $q = new useQuasar();
    let product = reactive({
      name: "",
      description: "",
      category_id: "",
      provider_id: "",
      product_image: "",
      groups: [],
    });
    let categories = ref([]);
    let showDialogCategory = ref(false);

    async function _getCategories() {
      loading.value = true;
      _wareHouseService
        .getCategories()
        .then((response) => {
          response.data.map((item) => {
            categories.value.push({
              label: item.description,
              value: item.id,
            });
          });
          loading.value = false;
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    async function _getProviders() {
      loading.value = true;
      _wareHouseService
        .getProviders()
        .then((response) => {
          response.data.map((item) => {
            providers.value.push({
              label: item.name,
              value: item.id,
            });
          });
          loading.value = false;
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    onMounted(async () => {
      await _getCategories();
      await _getProviders();
      await _getAllGroups();

      if (_checksIfUserIsMater()) {
        await _getAllCompanies();
      }
      await _getProduct();
    });

    function _checksIfUserIsMater() {
      let user = JSON.parse(localStorage.getItem("vuex")).userdata;
      isMaster.value = user.master == 1;
      return isMaster.value;
    }

    async function _getProduct() {
      const productId = route.query.productId;
      if (productId != null) {
        _showLoading();
        let response = await _wareHouseService.getProduct(productId);
        product.name = response.data.name;
        product.description = response.data.description;
        product.category_id = response.data.category_id;
        product.provider_id = response.data.provider_id;
        response.data.images.forEach((image) => {
          imageUrl.value = url_aws_bucket + image.path;
          product.product_image = image.path;
        });
        response.data.restrictedGroups.forEach((group) => {
          product.groups.push(group);
        });

        if (isMaster.value) {
          if (response.data.company_id != 0) {
            company_id.value = response.data.company_id;
            _filterGroupsWithCompanies();
          }
        }

        _hideLoading();
      }
    }

    function _filterGroupsWithCompanies() {
      listGroups.value = optionListGroupsByCompany.value.filter(
        (v) => v.company_id == company_id.value
      );
      optionListGroups.value = listGroups.value;
    }

    const sendEventRemoveImage = () => {
      imageUrl.value = "";
      refFileInput.value = null;
      file.value = "";
    };

    const onEventFilePicked = (event) => {
      const files = event.target.files;
      file.value = files[0];
      product.product_image = null;
      const filename = files[0].name;
      if (filename.lastIndexOf(".") <= 0) {
        return alert("Por favor adicione um arquivo válido");
      }
      const fileReader = new FileReader();
      fileReader.addEventListener("load", (event) => {
        imageUrl.value = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
    };

    const sendEventAddImage = () => {
      refFileInput.value.click();
    };

    async function addNewCategory(newCategoryName, event) {
      event.preventDefault();
      //loading.value = true;

      if (newCategoryName == "") {
        _alertNotify("Você precisa preencher o campo nome da categoria");
        loading = false;
        return;
      }

      const formData = new FormData();
      formData.append("name", newCategoryName);

      await _wareHouseService
        .addCategories(formData)
        .then(async () => {
          categories.value = [];
          await _getCategories();
          _alertNotify("Categoria adicionada com sucesso", "green-9");
          // categories.value.push({
          //   label: newCategoryName,
          // });
        })
        .catch((error) => {
          _alertNotify(error.response.data.toString());
        });
      newCategoryName = "";
    }

    async function editCategory(category, event) {
      const { label, value } = category;

      if (label == "" && (value ?? false)) {
        _alertNotify("Você precisa preencher o campo nome da categoria");
        return;
      }

      await _wareHouseService
        .updateCategory(value, label)
        .then((response) => {
          if (!response.data) {
            $q.notify({
              message: `Erro ao tentar atualizar a categoria: <strong>${label}</strong>?`,
              html: true,
              type: "negative",
              position: "top",
            });
            return;
          }
          categories.value = [];
          _getCategories();
        })
        .catch((error) => {
          $q.notify({
            message: `Erro ao tentar atualizar a categoria para o nome: <strong>${label}</strong>?`,
            html: true,
            type: "negative",
            position: "top",
          });
        });
    }

    function removeCategory(category) {
      const { label, value } = category;

      $q.notify({
        message: `Deseja remover o categoria <strong>${label}</strong>?`,
        html: true,
        type: "warning",
        position: "top",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              await _wareHouseService
                .removeCategory(value)
                .then((response) => {
                  if (!response.data) {
                    $q.notify({
                      message: `Erro ao tentar remover o fornecedor <strong>${label}</strong>!`,
                      html: true,
                      type: "negative",
                      position: "top",
                    });
                    return;
                  }
                  categories.value = [];
                  _getCategories();
                })
                .catch((error) => {
                  $q.notify({
                    message: `Erro ao tentar remover a categoria <strong>${label}</strong>!`,
                    caption: "",
                    html: true,
                    type: "negative",
                    position: "top",
                  });
                });
            },
          },
        ],
      });
    }

    async function addOrUpdateProduct() {
      var formData = _treatmentFormData();

      const productId = route.query.productId;
      _showLoading();
      if (productId == null) {
        await _wareHouseService
          .addProduct(formData)
          .then((response) => {
            loading.value = false;
            router.push({ name: "product" });
          })
          .catch((error) => {
            $q.notify({
              message: error.response.data.toString(),
              color: "red-9",
              position: "top",
            });
            loading.value = false;
          });
      } else {
        await _wareHouseService
          .updateProduct(productId, formData)
          .then((response) => {
            loading.value = false;
            router.push({ name: "product" });
          })
          .catch((error) => {
            $q.notify({
              message: error.response.data.toString(),
              color: "red-9",
              position: "top",
            });
            loading.value = false;
          });
      }
      _hideLoading();
    }

    function _treatmentFormData() {
      var formData = new FormData();
      formData.append("name", product.name);
      formData.append("description", product.description);
      formData.append("category_id", product.category_id);
      formData.append("provider_id", product.provider_id);
      formData.append("Images", file.value);
      formData.append("ProductImages", product.product_image);
      product.groups.forEach((group) => {
        formData.append("RestrictedGroups", group);
      });

      return formData;
    }

    function _showLoading() {
      loading.value = true;
    }

    function _alertNotify(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    function _hideLoading() {
      loading.value = false;
    }

    async function _getAllGroups() {
      loading.value = true;
      _groupServices
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
            });
          });
          optionListGroups.value = listGroups.value;
          optionListGroupsByCompany.value = listGroups.value;
          loading.value = false;
        })

        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllCompanies() {
      loading.value = true;
      await _companyServices
        .getAll()
        .then((response) => {
          response.data.map((item) => {
            listCompanies.value.push({
              label: item.company_name,
              value: item.id,
            });
          });
          loading.value = false;
        })

        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    function _filterGroupsWithCompanies() {
      listGroups.value = optionListGroupsByCompany.value.filter(
        (v) => v.company_id == company_id.value
      );
      optionListGroups.value = listGroups.value;
    }

    function filterGroupByCompany() {
      groups.value = null;
      _filterGroupsWithCompanies();
    }

    function filterFnGroup(val, update) {
      if (val === "") {
        update(() => {
          listGroups.value = optionListGroups.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listGroups.value = optionListGroups.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    return {
      breadcrumbs,
      product,
      providers,
      loading,
      categories,
      imageUrl,
      file,
      refFileInput,
      company_id,
      listGroups,
      listCompanies,
      isMaster,
      sendEventAddImage,
      sendEventRemoveImage,
      onEventFilePicked,
      filterFnGroup,
      filterGroupByCompany,
      addOrUpdateProduct,
      showDialogCategory,
      addNewCategory,
      editCategory,
      removeCategory,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .page-content {
    height: 85vh;
    overflow-y: auto;
  }
  .title-form {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.45;
  }

  .content-new-training {
    height: 80vh;

    .content-thumb-training {
      height: 100%;
      .thumb-training {
        border-radius: 10px;
        .q-img {
          border-radius: 10px;
        }
      }
      .btn-thumb-training {
        .q-btn {
          text-transform: none !important;
          border-radius: 8px;
        }
      }
      .label-title-menus {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #686868;
      }
    }
    .content-form-training {
      height: 100%;

      .form-input {
        .title-input-form {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          opacity: 0.45;
        }
      }
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>
